import React from 'react';
import styled from 'styled-components';
import Footer from '../../layout/Footer';
import { background, breakpoint, color, layout, pageMargin, typography } from "../../shared/styles"
import AboutUsHeroPNG from "../../../assets/about-us-hero.png"
import Section from "../../layout/Section"
import SectionHeader from "../../layout/SectionHeader"

const HeroGraphicBackground = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: -80px;
  margin: 0 auto;

  width: 840px;
  height: 358px;
  background-image: url(${AboutUsHeroPNG});
  background-repeat: no-repeat;
  background-size: contain;
`;

const HeroGraphic = styled.div`
  position: relative;
  z-index: 1;
  
  user-select: none;
  
  height: 158px;
  @media (min-width: ${layout.viewportWidthMedium}px) {
    height: 158px;
  }
`;

export default function AboutScreen() {
  return (
    <>
      <Section backgroundColor={background.bg}>
        <SectionHeader>Redefining how people pay together</SectionHeader>
        <HeroGraphic>
          <HeroGraphicBackground></HeroGraphicBackground>
        </HeroGraphic>
      </Section>
      <Section>
        <SectionHeader>Our Story</SectionHeader>
        <p>Splyt was created to redefine how people pay together. The tools and payment methods available to groups have remained mostly unchanged over the past 20 years. Someone pays the bill for a group and then has to keep track of who owes them money. People forget, reminders get awkward; overall, it’s not fun to be owed money.</p>
        <p>Splyt helps groups to do more and stress less about money. We leverage the latest financial technologies to make group payments better. We got tired of waiting for the perfect payments app, so we built it ourselves. We hope you enjoy it as much as we do!</p>

      </Section>
      <Footer />
    </>
  );
}
